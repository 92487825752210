<template>
  <div class="wrap">
    <!--顶部面包靴-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/volunteer/volunteerGroups' }" class="fs20">志愿团体</el-breadcrumb-item>
      <el-breadcrumb-item class="fs20">{{type===1?'新增':type===2?'编辑':'详情'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--表单-->
    <el-form :inline="true" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="rule-form">
      <div class="title">团体基础信息</div>
      <el-row>
        <el-form-item label="志愿团体名称" prop="groupName">
          <el-input v-model="ruleForm.groupName" class="w230" placeholder="请输入" :disabled="disabledFlag"></el-input>
        </el-form-item>
        <el-form-item label="志愿团体ID" prop="groupId">
          <el-input v-model="ruleForm.groupId" class="w230" placeholder="请输入" :disabled="type===2||type===3"></el-input>
          <el-popover placement="top-start" title="提示" width="200" trigger="hover"
            content="志愿团体ID存在，请直接填写；若不存在，请不用填写，系统为您生成志愿团体ID">
            <span slot="reference"><i class="el-icon-warning"></i></span>
            <!-- <el-button slot="reference">hover 激活</el-button> -->
          </el-popover>
          <!-- <el-button type="primary" class="ml20" @click="setId" v-if="type===1">生成志愿团体ID</el-button> -->
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="所在区域" prop="regional">
          <el-select v-model="ruleForm.regional" placeholder="请选择" class="w230" :disabled="disabledFlag">
            <el-option :label="item.organizationName" :value="item.organizationId+''" v-for="item in regionList"
              :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成立日期" prop="createTime">
          <el-date-picker type="date" placeholder="请选择日期" value-format="yyyy-MM-dd" v-model="ruleForm.createTime"
            class="w230" :disabled="disabledFlag">
          </el-date-picker>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="详细地址" prop="fullAddress">
          <el-input type="textarea" v-model="ruleForm.fullAddress" placeholder="请输入详细地址" class="w230"
            :disabled="disabledFlag"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="头像" prop="avatar">
          <el-image v-if="this.type===3" class="img-wrap" :src="ruleForm.avatar" fit="fit"
            @click="seeImage(ruleForm.avatar)"></el-image>
          <UploadAvatar v-else @getImgUrl="getImgUrl" url="/volunteerGroup/uploadImg" :imgUrl="ruleForm.avatar" />
        </el-form-item>
      </el-row>
      <div class="title">负责人/联系人信息</div>
      <el-row>
        <el-form-item label="姓名" prop="contacts">
          <el-input v-model="ruleForm.contacts" placeholder="请输入姓名" class="w230" :disabled="disabledFlag"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="contactPhone">
          <el-input v-model="ruleForm.contactPhone" placeholder="请输入手机号" class="w230" :disabled="disabledFlag">
          </el-input>
        </el-form-item>
      </el-row>
      <div class="title">账号信息</div>
      <el-row>
        <el-form-item label="账号" prop="accountNumber">
          <el-input v-model="ruleForm.accountNumber" placeholder="请输入账号" class="w230" :disabled="disabledFlag">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row v-if="type!==3">
        <el-form-item label="密码" prop="password" style="margin-bottom:32px">
          <el-input show-password clearable class="w230 password" placeholder="请输入密码" v-model="ruleForm.password"
            :disabled="disabledFlag">
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input show-password clearable class="w230" placeholder="请确认密码" v-model="confirmPassword"
            :disabled="disabledFlag">
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="所属部门" prop="departmentId">
          <el-cascader class="w230" :options="treedata" :props="defaultProps"
            ref="cascaderAddr" v-model="ruleForm.departmentId" :show-all-levels="false" clearable
            :disabled="disabledFlag">
          </el-cascader>
        </el-form-item>
        <el-form-item label="所属角色" prop="roleId">
          <el-select class="w230" v-model="ruleForm.roleId" placeholder="请选择" clearable :disabled="disabledFlag">
            <el-option v-for="item in roleListConfig" :label="item.roleName" :value="item.id" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-form-item class="btns">
        <el-button @click="back">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-if="type!==3">{{type===1?'提交':'修改'}}</el-button>
      </el-form-item>
    </el-form>
    <!-- 查看图片弹窗 -->
    <ImgaeDialog v-if="imageVisible" :imageVisible.sync="imageVisible" :imagesList="imagesList" />
  </div>
</template>

<script>
import {
  addVolunteerGroup,
  backDepartmentAndRole,
  backRegionList,
  generateGroupId,
  updateVolunteerGroup,
  backDepartmentTreeNodeList
} from '@/api/volunteer'
import { selectRole } from '@/api/userManage'
import UploadAvatar from '@/components/UploadAvatar'
export default {
  components: {
    UploadAvatar
  },
  data() {
    var validateGroupId = (rule, value, callback) => {
      const reg = /^[0-9]*$/
      if (this.type === 1) {
        if (value === '') {
          callback()
        } else if (!reg.test(value)) {
          callback('志愿团体ID只能是数字')
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      const pwpattent =
        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/
      if (pwpattent.test(value)) {
        callback()
        // getPassword(value)
      } else {
        callback(new Error('密码必须是8-16位大小写英文字母、数字、字符组合。'))
      }
    }
    var validatePass2 = (rule, value, callback) => {
      // console.log(this.ruleForm.password, this.confirmPassword)
      if (this.confirmPassword === '') {
        callback(new Error('请再次输入密码'))
      } else if (this.confirmPassword !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      imageVisible: false,
      options: [],
      imagesList: [],
      ruleForm: {
        accountNumber: '',
        avatar: '',
        contactPhone: '',
        contacts: '',
        createTime: '',
        departmentId: '', //返回数组
        fullAddress: '',
        groupId: '',
        groupName: '',
        password: '',
        regional: '',
        roleId: ''
      },
      rules: {
        groupName: [
          { required: true, message: '请输入志愿团体名称', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        groupId: [
          { required: false, validator: validateGroupId, trigger: 'blur' }
        ],
        contacts: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        contactPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: '请正确输入11位手机号码',
            trigger: 'blur'
          }
        ],
        accountNumber: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        departmentId: [
          { required: true, message: '请选择部门', trigger: 'change' }
        ],
        roleId: [{ required: true, message: '请选择角色', trigger: 'change' }]
      },
      defaultProps: {
        value: 'id',
        label: 'label',
        checkStrictly: true
      },
      regionList: [],
      // departmentName: '',
      // roleName: '',
      confirmPassword: '',
      info: {},
      type: 1, // 1新增 2编辑 3详情
      disabledFlag: false,
      treedata: [],
      roleListConfig: []
    }
  },
  mounted() {
    this.init()
    this.backRegionList()
    // this.backDepartmentAndRole()
    this.getDepartmentTreeList()
    this.selectRoleData()
  },
  methods: {
    init() {
      this.type = Number(this.$route.query.type)
      if (this.type === 2 || this.type === 3) {
        this.info = JSON.parse(this.$route.query.info)
        this.ruleForm = this.info
        this.confirmPassword = this.info.password
      }
      if (this.type === 3) {
        this.disabledFlag = true
      }
    },
    //  获取数数据
    async getDepartmentTreeList() {
      const res = await backDepartmentTreeNodeList()
      this.treedata = res.data
    },
    async selectRoleData() {
      const res = await selectRole()
      this.roleListConfig = res.data
    },
    async setId() {
      const res = await generateGroupId()
      console.log(res.data)
      this.ruleForm.groupId = res.data
    },
    async addVolunteerGroup() {
      const data = Object.assign({}, this.ruleForm)
      data.departmentId =
        this.ruleForm.departmentId[this.ruleForm.departmentId.length - 1]
      const res = await addVolunteerGroup(data)
      this.$message.success(res.data)
      this.back()
    },
    async updateVolunteerGroup() {
      const res = await updateVolunteerGroup(this.ruleForm)
      this.$message.success(res.data)
      this.back()
    },
    async backRegionList() {
      const res = await backRegionList()
      this.regionList = res.data
    },
    // async backDepartmentAndRole() {
    //   const res = await backDepartmentAndRole()
    //   this.departmentName = res.data.volunteerDepartment.departmentName
    //   this.ruleForm.departmentId = res.data.volunteerDepartment.id
    //   this.roleName = res.data.role.roleName
    //   this.ruleForm.roleId = res.data.role.id
    // },
    getImgUrl(url) {
      this.ruleForm.avatar = url
    },
    seeImage(url) {
      const imgsList = [{ url: url }]
      this.imagesList = imgsList
      this.imageVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type === 1) {
            this.addVolunteerGroup()
          } else {
            this.updateVolunteerGroup()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    back() {
      this.$router.push({ path: '/volunteer/volunteerGroups' })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-warning {
  margin-left: 10px;
  font-size: 20px;
  color: #DCDFE6;
}
.wrap {
  background-color: #ffffff;
  padding: 20px 20px 160px;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.w230 {
  width: 230px;
}
.fs20 {
  font-size: 20px;
}
.rule-form {
  margin: 30px 0 0 30px;
}
// .el-input, .el-select, .el-date-editor{
//   width: 230px;
// }
.img-wrap {
  width: 120px;
  height: 120px;
  border-radius: 6px;
}
.btns {
  padding-left: 120px;
}
</style>
</style>
