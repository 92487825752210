<template>
  <div class="myDiv">
    <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :headers="myHeaders"
      :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg">
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      <div slot="tip" class="el-upload__tip">上传头像格式为jpeg/jpg/png,大小在100kb以内</div>
    </el-upload>
  </div>
</template>
<script>
import config from '@/api/config'
export default {
  name: '',
  components: {},
  props: {
    imgUrl: String,
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageUrl: '',
      myHeaders: {
        Authorization: JSON.parse(localStorage.getItem('cad_userInfo'))?.token
      }
    }
  },
  watch: {
    imgUrl(val) {
      this.imageUrl = val
    }
  },
  computed: {
    uploadUrl() {
      return config.CIVIL_HOST + this.url
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleAvatarSuccess(file) {
      //   this.imageUrl = URL.createObjectURL(file.raw)
      if (file.state === 0) {
        this.imageUrl = file.data
        this.$emit('getImgUrl', file.data)
      } else {
        this.$message.error('头像上传失败')
      }
    },
    beforeAvatarUpload(file) {
      console.log(file.size)
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 < 100
      if (!isJPG) {
        this.$message.error('只支持上传jpeg、png、jpg等图片格式')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 100kb!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>